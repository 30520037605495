import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from "../data/languages";
import i18n from "../i18n";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const pathLang = location.pathname.split("/")[1];

  const [language, setLanguage] = useState(
    SUPPORTED_LANGUAGES[pathLang] ? pathLang : DEFAULT_LANGUAGE
  );

  useEffect(() => {
    const currentLang = location.pathname.split("/")[1];
    if (SUPPORTED_LANGUAGES[currentLang]) {
      setLanguage(currentLang);
    }
  }, [location]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const switchLanguage = (lang) => {
    if (SUPPORTED_LANGUAGES[lang]) {
      setLanguage(lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
