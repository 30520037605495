import Home from "../components/mainContent/home/Home";
import Faq from "./../components/mainContent/faq/Faq";
import Features from "../components/mainContent/features/Features";
import Contact from "../components/mainContent/contact/Contact";
import DeleteAccount from "../components/mainContent/deleteAccount/DeleteAccount";

const ROUTES = [
  { basicPath: "/", title: "home", component: Home, unnavigable: true },
  {
    basicPath: "/deleteaccount",
    title: "deleteAccount",
    component: DeleteAccount,
    unnavigable: true,
  },
  { basicPath: "/features", title: "features", component: Features },
  { basicPath: "/faq", title: "faq", component: Faq },
  { basicPath: "/contact", title: "contact", component: Contact },
];

const NAVIGATION_ROUTES = ROUTES.filter((route) => !route.unnavigable);

export function getRoutes(language = "en") {
  return ROUTES.map((route) => {
    let path = `/${language}${route.basicPath}`;
    if (route.basicPath === "*") {
      path = `/${language}/*`;
    }
    return {
      ...route,
      path: path,
    };
  });
}

export function getNavigationRoutes(language = "en") {
  return NAVIGATION_ROUTES.map((route) => {
    let path = `/${language}${route.basicPath}`;
    if (route.basicPath === "*") {
      path = `/${language}/*`;
    }
    return {
      ...route,
      path: path,
    };
  });
}

export function getNavigationRoute(title, language = "en") {
  const route = NAVIGATION_ROUTES.find((route) => route.title === title);
  if (!route) {
    throw new Error(`Route with title '${title}' not found.`);
  }

  let path = `/${language}${route.basicPath}`;
  if (route.basicPath === "*") {
    path = `/${language}/*`;
  }

  return {
    ...route,
    path: path,
  };
}
