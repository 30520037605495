import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MenuItem,
  Button,
  Container,
  Menu,
  Typography,
  IconButton,
  Toolbar,
  Box,
  AppBar,
} from "@mui/material";
import { getNavigationRoutes } from "../../data/routes";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import SelectLanguage from "./SelectLanguage";
import { useTranslation } from "react-i18next";

const appTitle = "JokesPortal";

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const { language } = useLanguage();
  const localizedRoutes = getNavigationRoutes(language);
  const { t } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handlePageSelected = (path) => {
    if (path !== null) {
      navigate(path);
    }
    setAnchorElNav(null);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="/jokes-portal-logo-512.png"
            alt="JokesPortal logo"
            className="logo-image small-hidden"
            onClick={handleLogoClick}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={handleLogoClick}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
            }}
          >
            {appTitle}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handlePageSelected(null)}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {localizedRoutes.map((route) => (
                <MenuItem
                  key={route.title}
                  onClick={() => handlePageSelected(route.path)}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    {t(`routes.${route.title}`)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <img
            src="/jokes-portal-logo-512.png"
            alt="JokesPortal logo"
            className="logo-image medium-hidden"
            onClick={handleLogoClick}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={handleLogoClick}
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              cursor: "pointer",
            }}
          >
            {appTitle}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {localizedRoutes.map((route) => (
              <Button
                key={route.title}
                onClick={() => handlePageSelected(route.path)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t(`routes.${route.title}`)}
              </Button>
            ))}
          </Box>
          <SelectLanguage />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
