import React from "react";
import { Typography, Box, Stack } from "@mui/material";

export default function Page({ title, description, children }) {
  return (
    <Box>
      <Stack spacing={2}>
        <section>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="h2" mb={2}>
            {description}
          </Typography>
        </section>
        {children}
      </Stack>
    </Box>
  );
}
