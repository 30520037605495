import { Typography } from "@mui/material";
import { PLAY_STORE_APP_LINK } from "../../data/links";
import { useTranslation } from "react-i18next";

export default function DoNotWait() {
  const { t } = useTranslation();

  return (
    <section>
      <Typography variant="h3" sx={{ pt: 1 }}>
        {t("doNotWait.title")}
      </Typography>
      <Typography>
        {t("doNotWait.description.beforeLink")}{" "}
        <a href={PLAY_STORE_APP_LINK}>{t("doNotWait.description.link")}</a>{" "}
        {t("doNotWait.description.afterLink")}
      </Typography>
    </section>
  );
}
