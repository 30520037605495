export const DEFAULT_LANGUAGE = "en";

export const SUPPORTED_LANGUAGES = {
  en: {
    image: "/flags/gb.svg",
    displayName: "EN",
  },
  pl: {
    image: "/flags/pl.svg",
    displayName: "PL",
  },
};
