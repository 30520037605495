import React from "react";
import Page from "../Page";
import { Typography, Link } from "@mui/material";
import { PRIVACY_PAGE_LINK, JOKESPORTAL_EMAIL } from "../../../data/links";
import { useTranslation } from "react-i18next";

export default function DeleteAccount() {
  const { t } = useTranslation();

  return (
    <Page
      title={t("deleteAccount.title")}
      description={t("deleteAccount.description")}
    >
      <Typography variant="h3">
        {t("deleteAccount.howToDeleteAccount.header")}
      </Typography>
      <Typography>
        {t("deleteAccount.howToDeleteAccount.description")}{" "}
        <a href={`mailto:${JOKESPORTAL_EMAIL}`}>{JOKESPORTAL_EMAIL}</a>
      </Typography>
      <Typography variant="h3">
        {t("deleteAccount.whatDataWillBeDeleted.header")}
      </Typography>
      <Typography>
        {t("deleteAccount.whatDataWillBeDeleted.description")}{" "}
        <Link
          href={PRIVACY_PAGE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("deleteAccount.whatDataWillBeDeleted.privacyLinkText")}
        </Link>
      </Typography>
    </Page>
  );
}
