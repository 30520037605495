import React, { useState } from "react";
import {
  Button,
  Box,
  CardActionArea,
  CardActions,
  Card,
  CardContent,
  Typography,
  Modal,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const cardSize = { sm: 6, md: 4, lg: 2 };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "LightSkyBlue",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FeatureCard({ title, shortDescription, description }) {
  const [openedFeatureDetails, setOpenedFeatureDetails] = useState(false);

  function handleOpenFeatureDetails() {
    setOpenedFeatureDetails(true);
  }

  function handleCloseFeatureDetails() {
    setOpenedFeatureDetails(false);
  }

  return (
    <>
      <Grid size={cardSize}>
        <Card style={{ backgroundColor: "LightSkyBlue" }}>
          <CardActionArea onClick={handleOpenFeatureDetails}>
            <CardContent>
              <Typography variant="h4">{title}</Typography>
              <Typography variant="body1">{shortDescription}</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" onClick={handleOpenFeatureDetails}>
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Modal
        open={openedFeatureDetails}
        onClose={handleCloseFeatureDetails}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <Typography id="feature-detail-title" variant="h4">
            {title}
          </Typography>
          <Typography id="feature-detail-short-description" variant="h5" mb={2}>
            {shortDescription}
          </Typography>
          <Typography id="feature-detail-description" variant="body1" mb={2}>
            {description}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCloseFeatureDetails}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}
