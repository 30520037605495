import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Page from "../Page";
import DoNotWait from "../DoNotWait";
import { useTranslation } from "react-i18next";
import { getNavigationRoute } from "../../../data/routes";
import { useLanguage } from "../../../contexts/LanguageContext";

const whatSetsUsApart = [
  "dynamic_database",
  "interaction_with_content",
  "simple_navigation",
  "jokes_in_multiple_languages",
];

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const featuresLink = getNavigationRoute("features", language).path;

  return (
    <Page title={t("home.title")} description={t("home.description")}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 8 }}>
          <section>
            <Typography variant="h3">{t("home.aboutAppTitle")}</Typography>
            <Typography>{t("home.aboutAppDescription")}</Typography>
          </section>
          <section>
            <Typography variant="h3" sx={{ pt: 1 }}>
              {t("home.whatSetsUsApartTitle")}
            </Typography>
            <Typography component="div">
              <ul>
                {whatSetsUsApart.map((whatSetsUsApartKey) => (
                  <li key={whatSetsUsApartKey}>
                    {t(`home.whatSetsUsApart.${whatSetsUsApartKey}`)}
                  </li>
                ))}
              </ul>
            </Typography>
          </section>
          <section>
            <Typography variant="h3" sx={{ pt: 1 }}>
              {t("home.prepareForMoreTitle")}
            </Typography>
            <Typography>
              {t("home.prepareForMoreDescription")}{" "}
              <Link component="button" onClick={() => navigate(featuresLink)}>
                {t("home.prepareForMoreLink")}
              </Link>
            </Typography>
          </section>
          <DoNotWait />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <img
            src="/jokes-on-phone.png"
            alt="Hand keeping a phone with JokesPortal application"
            style={{
              objectFit: "contain",
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
