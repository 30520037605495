import { createTheme } from "@mui/material";

export const MUI_DEFAULT_THEME = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
});
