import React from "react";
import Page from "../Page";
import DoNotWait from "../DoNotWait";
import FeatureCard from "./FeatureCard";
import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFeatureData } from "../../../hooks/useFeatureData";

export default function Features() {
  const { t } = useTranslation();
  const { existingFeatures, upcomingFeatures } = useFeatureData();

  return (
    <Page title={t("features.title")} description={t("features.description")}>
      <Typography variant="h3">
        {t("features.existingFeaturesHeader")}
      </Typography>
      <Grid container spacing={2}>
        {existingFeatures.map((feature) => (
          <FeatureCard
            key={feature.title}
            title={feature.title}
            shortDescription={feature.shortDescription}
            description={feature.description}
          />
        ))}
      </Grid>
      <Typography variant="h3">
        {t("features.upcomingFeaturesHeader")}
      </Typography>
      <Grid container spacing={2}>
        {upcomingFeatures.map((feature) => (
          <FeatureCard
            key={feature.title}
            title={feature.title}
            shortDescription={feature.shortDescription}
            description={feature.description}
          />
        ))}
      </Grid>
      <DoNotWait />
    </Page>
  );
}
