import React from "react";
import { Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

export const useFaqQuestions = () => {
  const { t } = useTranslation();

  const faqQuestions = {
    Content: {
      title: t("faq.faqCategories.content"),
      questions: [
        {
          title: t("faq.availableLanguages.title"),
          question: t("faq.availableLanguages.question"),
          answer: <Typography>{t("faq.availableLanguages.answer")}</Typography>,
        },
        {
          title: t("faq.viewFavoriteJokes.title"),
          question: t("faq.viewFavoriteJokes.question"),
          answer: <Typography>{t("faq.viewFavoriteJokes.answer")}</Typography>,
        },
      ],
    },
    Account: {
      title: t("faq.faqCategories.account"),
      questions: [
        {
          title: t("faq.loginRequired.title"),
          question: t("faq.loginRequired.question"),
          answer: <Typography>{t("faq.loginRequired.answer")}</Typography>,
        },
        {
          title: t("faq.accountDeletion.title"),
          question: t("faq.accountDeletion.question"),
          answer: (
            <Typography>
              {t("faq.accountDeletion.answer")}{" "}
              <Link href="/deleteaccount" underline="hover">
                {t("faq.accountDeletion.deleteAccountLink")}
              </Link>
            </Typography>
          ),
        },
      ],
    },
  };

  return faqQuestions;
};
