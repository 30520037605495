import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { getRoutes } from "../../data/routes";
import { Box } from "@mui/material";
import NotFound from "./notFound/NotFound";

export default function MainContent() {
  const { language } = useLanguage();
  const localizedRoutes = getRoutes(language);

  return (
    <Box flex="1" sx={{ p: 2, bgcolor: "background.default" }}>
      <Routes>
        <Route
          key="Root"
          path="/"
          element={<Navigate replace to={`/${language}`} />}
        />
        <Route key="NotFound" path="*" element={<NotFound />} />
        {localizedRoutes.map((route) => (
          <Route
            key={route.title}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </Box>
  );
}
