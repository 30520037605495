import * as React from "react";
import Question from "./Question";
import Page from "../Page";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFaqQuestions } from "../../../hooks/useFaqQuestions";

export default function Faq() {
  const { t } = useTranslation();
  const questions = useFaqQuestions();

  return (
    <Page title={t("faq.title")} description={t("faq.description")}>
      {Object.entries(questions).map(([category, { title, questions }]) => (
        <div key={category}>
          <Typography variant="h3" style={{ marginTop: 20 }}>
            {title}
          </Typography>
          {questions.map((faq) => (
            <Question
              key={faq.title}
              title={faq.title}
              question={faq.question}
              answer={faq.answer}
              link={faq.link}
            />
          ))}
        </div>
      ))}
    </Page>
  );
}
