import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useLanguage } from "../../contexts/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SUPPORTED_LANGUAGES } from "../../data/languages";

export default function SelectLanguage() {
  const location = useLocation();
  const { language, switchLanguage } = useLanguage();
  const navigate = useNavigate();

  function handleLanguageChange(event) {
    const newLang = event.target.value;
    const currentPath = location.pathname;

    const newPath = currentPath.replace(
      /^\/[a-z]{2}(\/?)(.*)$/,
      `/${newLang}$1$2`
    );

    switchLanguage(newLang);
    navigate(newPath, { replace: true });
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Select
        value={language}
        onChange={handleLanguageChange}
        sx={{ color: "white", display: "flex", alignItems: "center" }}
      >
        {Object.entries(SUPPORTED_LANGUAGES).map(
          ([langCode, { image, displayName }]) => (
            <MenuItem
              key={langCode}
              value={langCode}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={image}
                alt={`${displayName} flag`}
                style={{ width: 24, height: "auto", marginRight: 8 }}
              />
              {displayName}
            </MenuItem>
          )
        )}
      </Select>
    </Box>
  );
}
