import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { QRCodeSVG } from "qrcode.react";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { ReactComponent as PlayStoreIcon } from "../../assets/icons/playstore-svgrepo-com.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord-icon-svgrepo-com.svg";
import {
  PLAY_STORE_APP_LINK,
  DISCORD_COMMUNITY_LINK,
  PRIVACY_PAGE_LINK,
  TERMS_OF_USE_PAGE_LINK,
} from "../../data/links";
import { useTranslation } from "react-i18next";

const svgSize = 18;
const qrSize = 80;

const buttonWidth = "250px";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        py: 2,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <QRCodeSVG value={PLAY_STORE_APP_LINK} size={qrSize} />
        </Grid>
        <Grid xs={12} md={6} textAlign="center">
          <Stack spacing={1} alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PlayStoreIcon width={svgSize} height={svgSize} />}
              component="a"
              href={PLAY_STORE_APP_LINK}
              sx={{ mb: 1, width: buttonWidth }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.getOnPlayStoreButtonText")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DiscordIcon width={svgSize} height={svgSize} />}
              component="a"
              href={DISCORD_COMMUNITY_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: buttonWidth,
              }}
            >
              {t("footer.joinDiscordButtonText")}
            </Button>
          </Stack>
          <Box mt={2}>
            <Link
              href={PRIVACY_PAGE_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", marginRight: "2rem" }}
            >
              Privacy Policy
            </Link>
            <Link
              href={TERMS_OF_USE_PAGE_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white" }}
            >
              Terms of Use
            </Link>
          </Box>
          <Typography mt={2}>© 2024 All rights reserved.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
