import { useTranslation } from "react-i18next";

export function useFeatureData() {
  const { t } = useTranslation();

  const existingFeatures = [
    {
      title: t("features.existing.dynamicContent.title"),
      shortDescription: t("features.existing.dynamicContent.shortDescription"),
      description: t("features.existing.dynamicContent.description"),
    },
    {
      title: t("features.existing.multipleLanguages.title"),
      shortDescription: t(
        "features.existing.multipleLanguages.shortDescription"
      ),
      description: t("features.existing.multipleLanguages.description"),
    },
  ];

  const upcomingFeatures = [
    {
      title: t("features.upcoming.favoriteJokes.title"),
      shortDescription: t("features.upcoming.favoriteJokes.shortDescription"),
      description: t("features.upcoming.favoriteJokes.description"),
    },
    {
      title: t("features.upcoming.sharingJokes.title"),
      shortDescription: t("features.upcoming.sharingJokes.shortDescription"),
      description: t("features.upcoming.sharingJokes.description"),
    },
    {
      title: t("features.upcoming.categories.title"),
      shortDescription: t("features.upcoming.categories.shortDescription"),
      description: t("features.upcoming.categories.description"),
    },
  ];

  return { existingFeatures, upcomingFeatures };
}
