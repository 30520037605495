import Page from "../Page";
import { Typography, Button } from "@mui/material";
import { DISCORD_COMMUNITY_LINK, JOKESPORTAL_EMAIL } from "../../../data/links";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/discord-icon-svgrepo-com.svg";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Page title={t("contact.title")} description={t("contact.description")}>
      <Typography variant="h3">
        {t("contact.contactInformationTitle")}
      </Typography>
      <Typography>
        email: <a href={`mailto:${JOKESPORTAL_EMAIL}`}>{JOKESPORTAL_EMAIL}</a>
      </Typography>
      <Typography variant="h3">{t("contact.joinCommunity.title")}</Typography>
      <Typography>{t("contact.joinCommunity.description")}</Typography>
      <Button
        variant="outlined"
        startIcon={<DiscordIcon width={40} height={40} />}
        component="a"
        href={DISCORD_COMMUNITY_LINK}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          width: "fit-content",
        }}
      >
        {t("contact.joinDiscordButtonText")}
      </Button>
    </Page>
  );
}
