import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/header/Header";
import MainContent from "./components/mainContent/MainContent";
import Footer from "./components/footer/Footer";
import { MUI_DEFAULT_THEME } from "./data/mui-theme";
import { ThemeProvider, Box } from "@mui/material";
import { LanguageProvider } from "./contexts/LanguageContext";

function App() {
  return (
    <ThemeProvider theme={MUI_DEFAULT_THEME}>
      <Router>
        <LanguageProvider>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Header />
            <MainContent />
            <Footer />
          </Box>
        </LanguageProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
