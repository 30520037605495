import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE } from "./data/languages";

import homeEN from "./data/locales/en/home.json";
import homePL from "./data/locales/pl/home.json";

import doNotWaitEN from "./data/locales/en/doNotWait.json";
import doNotWaitPL from "./data/locales/pl/doNotWait.json";

import footerEN from "./data/locales/en/footer.json";
import footerPL from "./data/locales/pl/footer.json";

import routesEN from "./data/locales/en/routes.json";
import routesPL from "./data/locales/pl/routes.json";

import contactEN from "./data/locales/en/contact.json";
import contactPL from "./data/locales/pl/contact.json";

import faqEN from "./data/locales/en/faq.json";
import faqPL from "./data/locales/pl/faq.json";

import featuresEN from "./data/locales/en/features.json";
import featuresPL from "./data/locales/pl/features.json";

import deleteAccountEN from "./data/locales/en/deleteAccount.json";
import deleteAccountPL from "./data/locales/pl/deleteAccount.json";

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        ...homeEN,
        ...doNotWaitEN,
        ...footerEN,
        ...routesEN,
        ...contactEN,
        ...faqEN,
        ...featuresEN,
        ...deleteAccountEN,
      },
    },
    pl: {
      translation: {
        ...homePL,
        ...doNotWaitPL,
        ...footerPL,
        ...routesPL,
        ...contactPL,
        ...faqPL,
        ...featuresPL,
        ...deleteAccountPL,
      },
    },
  },
});

export default i18n;
